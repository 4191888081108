import React from 'react'
// import ReactDOM from 'react-dom'
import { NavBar, ListView, Icon, PullToRefresh, Toast, Modal } from 'antd-mobile'
import PropTypes from 'prop-types'
import Component, { mapStateToProps, mapDispatchToProps } from '../components/Component'
import { connect } from 'react-redux'
import Border from '../components/Border'
import style from './ApproveList.module.less'
import Row from '../components/ApproveList/Row'
const alert = Modal.alert
class ApproveList extends Component {
  constructor (props) {
    super(props)

    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => {
        return true
      }
    })

    this.state = {
      dataSource,
      isLoading: false,
      refreshing: false,
      height: document.documentElement.clientHeight * 3 / 4,
      multiSelecting: false,
      total: 0,
      pagination: {
        current: 1,
        page_size: 20
      },
      type: '未处理',
      types: ['未处理', '已处理']
    }
  }

  list = []
  listRef = React.createRef()
  containerRef = React.createRef()
  navbarRef = React.createRef()

  // 到达底部加载更多
  onEndReached () {
    const { total, pagination } = this.state
    const { current } = pagination
    if (this.list.length >= total) {
      return false
    }
    this.setState({
      isLoading: true,
      pagination: {
        ...this.state.pagination,
        current: current + 1
      }
    }, () => {
      this.getData()
    })
  }

  // 初始化列表容器高度
  initListHeight () {
    const totalHeight = this.containerRef.current.offsetHeight
    // const nav = ReactDOM.findDOMNode(this.navbarRef.current)
    // const navHeight = nav.offsetHeight
    this.setState({
      // height: totalHeight - navHeight
      height: totalHeight
    })
  }

  getData () {
    const { pagination, multiSelecting } = this.state
    const params = {
      ...pagination
    }
    const condition = [{ k: 'need_do', o: '=', v: 1 }, { k: 'title', o: 'not in', v: '流水号案件文书呈批,审限扣除、中止呈批' }]
    if (this.state.type === '未处理') {
      condition.push({ k: 'complete_datetime', o: 'null' })
    } else {
      condition.push({ k: 'complete_datetime', o: 'not null' })
    }
    if (multiSelecting) {
      condition.push({ k: 'auto_action', o: 'not null' })
    }
    params.condition = JSON.stringify(condition)
    return this.$get('/api/xapc/message', { params })
      .then(res => {
        this.setState({
          refreshing: false,
          isLoading: false
        })
        if (res.status === 1) {
          let list = this.list
          list = list.concat(res.data.list.map(li => {
            li.multi = this.state.multiSelecting
            li.checked = false
            try {
              li.route = JSON.parse(li.route)
            } catch (e) {
              li.route = null
            }
            return li
          }))
          this.list = list
          this.setState({
            total: res.data.total,
            dataSource: this.state.dataSource.cloneWithRows(list)
          })
        }
      }).catch(() => {
        this.setState({
          refreshing: false,
          isLoading: false
        })
      })
  }

  // 选择行数
  selectRowHandle (d, i, r) {
    if (!this.state.multiSelecting) {
      this.props.history.push(`/admin/approve-detail/${d.id}`)
      return false
    }
    if (d.multi && d.auto_action) {
      const list = this.list
      const ids = list.map(li => li.id)
      const index = ids.indexOf(d.id)
      const rowData = Object.assign({}, list[index], { checked: !d.checked })
      this.list.splice(index, 1, rowData)
      this.setState({
        dataSource: this.state.dataSource.cloneWithRows(this.list)
      })
    }
  }

  onRefreshHandle () {
    this.setState({
      refreshing: true,
      pagination: {
        ...this.state.pagination,
        current: 1
      }
    }, () => {
      this.list = []
      this.getData()
    })
  }

  // 改变类型
  changeType (v) {
    this.list = []
    this.setState({
      type: v,
      dataSource: this.state.dataSource.cloneWithRows([]),
      isLoading: true,
      pagination: {
        ...this.state.pagination,
        current: 1
      }
    }, () => {
      this.getData()
    })
  }

  // 隐藏多选
  hideMulti () {
    const list = this.list
    list.forEach(li => {
      li.multi = false
      li.checked = false
    })
    this.setState({
      multiSelecting: false
    }, () => {
      this.onRefreshHandle()
      // this.setState({
      //   dataSource: this.state.dataSource.cloneWithRows(list)
      // })
    })
  }

  openMulti () {
    const list = this.list
    list.forEach(li => {
      li.multi = true
    })
    this.setState({
      multiSelecting: true
    }, () => {
      this.onRefreshHandle()
      // this.setState({
      //   dataSource: this.state.dataSource.cloneWithRows(list)
      // })
    })
  }

  // 批量呈批请求
  multiRequest (list) {
    Toast.loading('处理中', 120)
    this.$post('/api/xapc/message/autoAction', {
      ids: list.join()
    }).then(res => {
      Toast.hide()
      if (res.status === 1) {
        Toast.success('处理成功')
        this.list = []
        this.setState({
          multiSelecting: false,
          dataSource: this.state.dataSource.cloneWithRows(this.list)
        }, () => {
          this.onRefreshHandle()
        })
      } else {
        Toast.warning(res.message)
      }
    }).catch(() => Toast.hide())
  }

  // 批量处理，二次确认
  confirmMulti () {
    const list = this.list.filter(li => li.checked)
    if (!list.length) {
      return Toast.info('请先勾选要批量处理的呈批')
    }
    alert('提示', '您确定要批量呈批?', [
      { text: '取消', onPress: () => {} },
      { text: '确定', onPress: () => this.multiRequest(list.map(li => li.id)) }
    ])
  }

  componentDidMount () {
    this.initListHeight()
    this.setState({
      isLoading: true
    }, () => {
      this.getData()
    })
  }

  componentDidUpdate (prev) {
    const { visible } = this.props
    if (visible && prev.visible !== visible) {
      this.initListHeight()
    }
  }

  renderRow (d, i, r, highlightRow) {
    return <Row key={r} data={d} selectRowHandle={(d, i, r) => this.selectRowHandle(d, i, r)} />
  }

  renderSeparator (d, i) {
    if (i === String(this.list.length - 1)) {
      return null
    }
    return (
      <Border key={`${d}-${i}`} top={false} left={false} right={false} radius={false}>
        <div className={style.separator} />
      </Border>
    )
  }

  renderHeader () {
    if (this.state.multiSelecting) {
      return (
        <NavBar
          ref={this.navbarRef}
          mode='light'
          leftContent={<span className={style['approve-back-btn']}>取消</span>}
          onLeftClick={() => this.hideMulti()}
          rightContent={<span className={style['approve-decide-btn']} onClick={() => this.confirmMulti()}>确认呈批</span>}
        />
      )
    }
    // const types = this.state.types
    // const index = types.indexOf(this.state.type)
    return (
      <NavBar
        ref={this.navbarRef}
        mode='light'
        rightContent={<span className={style['approve-back-btn']} onClick={() => this.openMulti()}>批量呈批</span>}
      >
        {/* <SegmentedControl className={style.controller} selectedIndex={index} values={types} value={this.state.type} onValueChange={v => this.changeType(v)} /> */}
      </NavBar>
    )
  }

  renderFooter () {
    const { total, isLoading } = this.state
    if (!isLoading) {
      if (this.list.length === 0) {
        return <div className={style.footer}>暂无呈批信息</div>
      }
      if (this.list.length >= total) {
        return <div className={style.footer}>没有更多了</div>
      }
    } else {
      return (
        <div className={style.footer}>
          <span className={style['footer-text']}>加载中</span>
          <Icon type='loading' />
        </div>
      )
    }
  }

  render () {
    const pageSize = this.state.pagination.page_size
    return (
      <div id='approveList' className={style.container} ref={this.containerRef}>
        {/* {this.renderHeader()} */}
        <ListView
          ref={this.listRef}
          dataSource={this.state.dataSource}
          renderRow={(d, i, r, highlightRow) => this.renderRow(d, i, r, highlightRow)}
          renderSeparator={(d, i) => this.renderSeparator(d, i)}
          style={{
            height: this.state.height,
            overflow: 'auto'
          }}
          pageSize={pageSize}
          scrollRenderAheadDistance={500}
          onEndReached={() => this.onEndReached()}
          onEndReachedThreshold={200}
          initialListSize={pageSize}
          renderFooter={() => this.renderFooter()}
          pullToRefresh={
            <PullToRefresh
              damping={60}
              indicator={this.state.down ? {} : { deactivate: '下拉刷新' }}
              refreshing={this.state.refreshing}
              onRefresh={() => this.onRefreshHandle()}
            />
          }
        />
      </div>
    )
  }
}

ApproveList.propTypes = {
  // showDetail: PropTypes.func,
  visible: PropTypes.bool
}

ApproveList.defaultProps = {
  showDetail: () => {},
  visible: false
}

const mapState = mapStateToProps(function (state) {
  return {
    userInfo: state.basic.userInfo
  }
})

const mapDispatch = mapDispatchToProps(function (dispatch) {
  return {

  }
})

export default connect(mapState, mapDispatch)(ApproveList)
