import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './TextList.module.less'
import Border from './Border'

export class TextList extends Component {
  render () {
    return (
      <div className={style.list}>
        <Border left={false} right={false} radius={false}>
          <div className={style['list-inner']}>
            {this.props.children}
          </div>
        </Border>
      </div>
    )
  }
}

export class TextItem extends Component {
  render () {
    return (
      <div className={style.item}>
        <div className={style['item-inner']}>
          <span className={style['item-title']}>{this.props.title}</span>
          &nbsp;
          <span className={style['item-value']}>
            <span className={style['item-text']}>{this.props.value}</span>
          </span>
        </div>
      </div>
    )
  }
}

TextItem.propTypes = {
  title: PropTypes.string,
  value: PropTypes.any
}

TextItem.defaultProps = {
  title: '',
  value: ''
}

export class ContentItem extends Component {
  render () {
    return (
      <div className={style['content-item']}>
        <span className={style['content-item-title-content']}>{this.props.titleContent}</span>
        <span className={style['content-item-value']}>
          <span className={style['item-text']}>{this.props.value}</span>
        </span>
      </div>
    )
  }
}

ContentItem.propTypes = {
  value: PropTypes.any,
  titleContent: PropTypes.node
}

ContentItem.defaultProps = {
  value: '',
  titleContent: null
}

export class TextTitle extends Component {
  render () {
    return (
      <div className={style.title}>
        <span>{this.props.children}</span>
        <span>{this.props.rightContent}</span>
      </div>
    )
  }
}

TextTitle.propTypes = {
  rightContent: PropTypes.string
}

TextTitle.defaultProps = {
  rightContent: ''
}

export class TextMulti extends Component {
  render () {
    const s = { }
    if (!this.props.bottom) {
      s.marginBottom = 0
    }
    return (
      <div className={style['text-multi']} style={s}>
        {this.props.children}
      </div>
    )
  }
}

TextMulti.propTypes = {
  bottom: PropTypes.bool
}

TextMulti.defaultProps = {
  bottom: true
}
