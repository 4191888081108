import React from 'react'
import PropTypes from 'prop-types'
import Component, { mapStateToProps, mapDispatchToProps } from '../../components/Component'
import { connect } from 'react-redux'
import { Picker, Toast, PullToRefresh } from 'antd-mobile'
import moment from 'moment'
import Icon from '../../components/Icon'
import Border from '../../components/Border'
import style from './LeaderDesktop.module.less'
import classNames from 'classnames'

class Home extends Component {
  state = {
    height: document.documentElement.clientHeight * 3 / 4,
    refreshing: false,
    years: this.initYears(),
    year: moment().year(),
    monday: Date.now(),
    nextMonday: Date.now(),
    weekRegisteryCaseList: [],
    weekDocketCaseNum: 0,
    weekDocketAmount: 0,
    weekDocketFee: 0,
    weekClosureCaseList: [],
    weekClosureCaseNum: 0,
    weekClosureAmount: 0,
    weekClosureFee: 0,
    yearDocketNum: 0,
    yearAcceptNum: 0,
    yearAntAcceptNum: 0,
    yearClosureNum: 0,
    yearDocketList: [],
    yearClosureList: []
  }

  containerRef = React.createRef()

  // 初始化星期一和星期日的时间戳,并获取数据
  initWeekDayAndGetData () {
    const time = moment(moment().format('YYYY-MM-DD'))
    const d = time.day()
    time.subtract(d, 'd')
    const monday = Math.floor(time.valueOf() / 1000)
    time.add(6, 'd')
    time.hour(23)
    time.minute(59)
    time.second(59)
    const nextMonday = Math.floor(time.valueOf() / 1000)
    this.setState({
      monday,
      nextMonday
    }, () => {
      this.getAllData()
    })
  }

  // 获得年份列表
  initYears () {
    const start = 2005
    const current = moment().year()
    const list = []
    for (let i = current; i >= start; i--) {
      list.push({ value: i, label: `${i}年` })
    }
    return list
  }

  // 获取周立案情况
  getWeekDocket () {
    return this.$get('/api/xapc/casestat', {
      params: {
        begintime: this.state.monday,
        endtime: this.state.nextMonday,
        type: 'docket_datetime',
        group: 'date',
        field: 'caseno,amountsum,feesum'
      }
    }).then(res => {
      if (res.status === 1) {
        const { total, list } = res.data
        this.setState({
          weekRegisteryCaseList: list.map(li => li.caseno),
          weekDocketCaseNum: total.caseno || 0,
          weekDocketAmount: total.amountsum || 0,
          weekDocketFee: total.feesum || 0
        })
      } else {
        Toast.fail(res.message)
      }
    })
  }

  // 获取周结案情况
  getWeekClosure () {
    return this.$get('/api/xapc/casestat', {
      params: {
        begintime: this.state.monday,
        endtime: this.state.nextMonday,
        type: 'closure_datetime',
        group: 'date',
        field: 'caseno,amountsum,feesum'
      }
    }).then(res => {
      if (res.status === 1) {
        const { total, list } = res.data
        this.setState({
          weekClosureCaseList: list.map(li => li.caseno),
          weekClosureCaseNum: total.caseno || 0,
          weekClosureAmount: total.amountsum || 0,
          weekClosureFee: total.feesum || 0
        })
      } else {
        Toast.fail(res.message)
      }
    })
  }

  // 获取年收结案数据
  getYearDocketAndClosure () {
    const { begintime, endtime } = this.getBeginAndEndTime(this.state.year)
    return Promise.all([this.getYearDocket(begintime, endtime), this.getYearClosure(begintime, endtime), this.getYearAccept(begintime, endtime), this.getYearAntAccept(begintime, endtime)])
      .then(res => {
        const docket = res[0]
        const closure = res[1]
        const accept = res[2]
        const antaccept = res[3]
        this.setState({
          yearDocketNum: docket.total.caseno,
          yearAcceptNum: accept.total.caseno,
          yearAntAcceptNum: antaccept.total.caseno,
          yearClosureNum: closure.total.caseno,
          yearDocketList: docket.list.map(li => li.caseno),
          yearClosureList: closure.list.map(li => li.caseno)
        })
      })
  }

  // 根据年获取开始时间戳和结束时间戳
  getBeginAndEndTime (year) {
    const m = moment(`${year}-01-01`)
    const begintime = Math.floor(m.valueOf() / 1000)
    m.add(1, 'y')
    m.subtract(1, 's')
    const endtime = Math.floor(m.valueOf() / 1000)
    return {
      begintime,
      endtime
    }
  }

  // 获取全年月份立案数据
  getYearDocket (begintime, endtime) {
    return this.$get('/api/xapc/casestat', {
      params: {
        begintime,
        endtime,
        type: 'docket_datetime',
        group: 'month',
        field: 'caseno,amountsum,feesum'
      }
    }).then(res => {
      if (res.status === 1) {
        return res.data
      } else {
        Toast.fail(res.message)
      }
    })
  }

  // 获取全年月份收案数据
  getYearAccept (begintime, endtime) {
    return this.$get('/api/xapc/casestat', {
      params: {
        begintime,
        endtime,
        type: 'accept_datetime',
        group: 'month',
        field: 'caseno,amountsum,feesum'
      }
    }).then(res => {
      if (res.status === 1) {
        return res.data
      } else {
        Toast.fail(res.message)
      }
    })
  }

  // 获取全年月份反请求收案数据
  getYearAntAccept (begintime, endtime) {
    return this.$get('/api/xapc/casestat', {
      params: {
        begintime,
        endtime,
        type: 'counter_accept_datetime',
        group: 'month',
        field: 'caseno,amountsum,feesum'
      }
    }).then(res => {
      if (res.status === 1) {
        return res.data
      } else {
        Toast.fail(res.message)
      }
    })
  }

  // 获取全年结案数据
  getYearClosure (begintime, endtime) {
    return this.$get('/api/xapc/casestat', {
      params: {
        begintime,
        endtime,
        type: 'closure_datetime',
        group: 'month',
        field: 'caseno,amountsum,feesum'
      }
    }).then(res => {
      if (res.status === 1) {
        return res.data
      } else {
        Toast.fail(res.message)
      }
    })
  }

  // 获取全部数据
  getAllData () {
    this.setState({ refreshing: true })
    Promise.all([this.getWeekDocket(), this.getWeekClosure(), this.getYearDocketAndClosure()])
      .then(() => {
        Toast.hide()
        this.setState({ refreshing: false })
      }).catch(() => {
        Toast.hide()
        this.setState({ refreshing: false })
      })
  }

  // 格式化金钱
  formatMoney (money) {
    const m = String(money)
    if (!/^\d*$/.test(m)) {
      return m
    }
    return m.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  // 改变年份
  changeYear (v) {
    this.setState({
      year: v
    }, () => {
      Toast.loading('加载中', 120)
      this.getYearDocketAndClosure()
        .then(() => {
          Toast.hide()
        }).catch(() => Toast.hide())
    })
  }

  initHeight () {
    this.setState({
      height: this.containerRef.current.offsetHeight
    })
  }

  componentDidMount () {
    this.initHeight()
    Toast.loading('加载中', 120)
    this.initWeekDayAndGetData()
  }

  componentDidUpdate (prev) {
    const { visible } = this.props
    if (visible && prev.visible !== visible) {
      this.initHeight()
    }
  }

  renderInfo () {
    return (
      <div style={{ minHeight: this.state.height + 'px' }}>
        <div className={style.block}>
          <div className={style.title}>
            <Border left={false} right={false} top={false} radius={false}>
              <div className={style['title-text']}>芜湖仲裁委员会</div>
            </Border>
          </div>
          <div className={style['week-block']}>
            <Border left={false} right={false} top={false} radius={false}>
              <div className={style['week-inner']}>
                <div className={[style['week-left'], style.warning].join(' ')}>
                  <div className={style['week-left-title']}>周实时立案</div>
                  <div className={style['week-left-value']}>
                    <span>{this.state.weekDocketCaseNum}</span>
                    <span className={style['week-left-unit']}>件</span>
                  </div>
                </div>
                <div className={style['week-right']}>
                  <div className={style['week-right-title']}>总标的</div>
                  <div className={style['week-right-value']}>
                    <span className={style['week-right-unit']}>¥</span>
                    <span>{this.formatMoney(this.state.weekDocketAmount)}</span>
                  </div>
                  <div className={style['week-right-title']}>总仲裁费</div>
                  <div className={style['week-right-value']}>
                    <span className={style['week-right-unit']}>¥</span>
                    <span>{this.formatMoney(this.state.weekDocketFee)}</span>
                  </div>
                </div>
              </div>
            </Border>
          </div>
          <div className={style['week-block']}>
            <div className={style['week-inner']}>
              <div className={[style['week-left'], style.primary].join(' ')}>
                <div className={style['week-left-title']}>周实时结案</div>
                <div className={style['week-left-value']}>
                  <span>{this.state.weekClosureCaseNum}</span>
                  <span className={style['week-left-unit']}>件</span>
                </div>
              </div>
              <div className={style['week-right']}>
                <div className={style['week-right-title']}>总标的</div>
                <div className={style['week-right-value']}>
                  <span className={style['week-right-unit']}>¥</span>
                  <span>{this.formatMoney(this.state.weekClosureAmount)}</span>
                </div>
                <div className={style['week-right-title']}>总仲裁费</div>
                <div className={style['week-right-value']}>
                  <span className={style['week-right-unit']}>¥</span>
                  <span>{this.formatMoney(this.state.weekClosureFee)}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={style.block}>
          <div className={style['year-selector']}>
            <Picker data={this.state.years} cols={1} value={[this.state.year]} onChange={v => this.changeYear(v[0])}>
              <div className={style['year-selector-inner']}>
                <span className={style['year-selector-text']}>{this.state.year}年</span>
                <Icon name='arrow-down-filling' />
              </div>
            </Picker>
          </div>
          <div className={style['year-block']}>
            <div className={classNames(style['year-left'], style.warning)}>
              <Border top={false} left={false} bottom={false} radius={false}>
                <div className={style['year-title']}>收案</div>
                <div className={style['year-value']}>
                  <span>{this.state.yearAcceptNum}</span>
                  <span className={style['year-unit']}>件</span>
                </div>
              </Border>
            </div>
            <div className={classNames(style['year-right'], style.ant)}>
              <div className={style['year-title']}>反请求收案</div>
              <div className={style['year-value']}>
                <span>{this.state.yearAntAcceptNum}</span>
                <span className={style['year-unit']}>件</span>
              </div>
            </div>
          </div>
          <div className={style['year-block']}>
            <div className={classNames(style['year-left'], style.primary)}>
              <Border top={false} left={false} bottom={false} radius={false}>
                <div className={style['year-title']}>结案</div>
                <div className={style['year-value']}>
                  <span>{this.state.yearClosureNum}</span>
                  <span className={style['year-unit']}>件</span>
                </div>
              </Border>
            </div>
            <div className={style['year-right']} />
          </div>
        </div>
      </div>
    )
  }

  render () {
    return (
      <div className={style.container} ref={this.containerRef}>
        <PullToRefresh
          refreshing={this.state.refreshing}
          damping={60}
          style={{
            height: this.state.height,
            overflow: 'auto'
          }}
          onRefresh={() => this.initWeekDayAndGetData()}
        >
          {this.renderInfo()}
        </PullToRefresh>
      </div>
    )
  }
}

Home.propTypes = {
  visible: PropTypes.bool
}

Home.defaultProps = {
  visible: false
}

const mapState = mapStateToProps(function (state) {
  return {
    userInfo: state.basic.userInfo
  }
})

const mapDispatch = mapDispatchToProps(function (dispatch) {
  return {

  }
})

export default connect(mapState, mapDispatch)(Home)
