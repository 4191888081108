// 设置基本信息
export const SET_USERINFO = 'SET_USERINFO' // 设置用户信息
export const SET_TOKEN = 'SET_TOKEN' // 设置token
export const SET_SEND_TIME = 'SET_SEND_TIME' // 设置发送时间
export const SET_LOGIN_MOBILE = 'SET_LOGIN_MOBILE' // 设置已发送短信的手机号

// 全局事件相关
export const ADD_SCROLL_EVENT = 'ADD_SCROLL_EVENT' // 添加滚动事件
export const DEL_SCROLL_EVENT = 'DEL_SCROLL_EVENT' // 移除滚动事件
export const CLEAR_SCROLL_EVENT = 'CLEAR_SCROLL_EVENT' // 清空滚动事件

export const ADD_RESIZE_EVENT = 'ADD_RESIZE_EVENT' // 添加窗口改变事件
export const DEL_RESIZE_EVENT = 'DEL_RESIZE_EVENT' // 移除窗口改变事件
export const CLEAR_RESIZE_EVENT = 'CLEAR_RESIZE_EVENT' // 清空窗口改变事件
