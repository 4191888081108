import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { ListView, PullToRefresh, Icon, SearchBar } from 'antd-mobile'
import Component, { mapStateToProps, mapDispatchToProps } from '../components/Component'
import { connect } from 'react-redux'
import Border from '../components/Border'
import style from './CaseList.module.less'
import Row from '../components/CaseList/Row'

class CaseList extends Component {
  constructor (props) {
    super(props)

    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1 !== row2
    })

    this.state = {
      dataSource,
      list: [],
      isLoading: false,
      refreshing: false,
      height: document.documentElement.clientHeight * 3 / 4,
      searchText: '',
      total: 0,
      pagination: {
        current: 1,
        page_size: 20
      }
    }
  }

  listRef = React.createRef()
  containerRef = React.createRef()
  navbarRef = React.createRef()

  // 到达底部加载更多
  onEndReached () {
    const { list, total, pagination } = this.state
    const { current } = pagination
    if (list.length >= total) {
      return false
    }
    this.setState({
      isLoading: true,
      pagination: {
        ...this.state.pagination,
        current: current + 1
      }
    }, () => {
      this.getData()
    })
  }

  // 初始化列表容器高度
  initListHeight () {
    const totalHeight = this.containerRef.current.offsetHeight
    const nav = ReactDOM.findDOMNode(this.navbarRef.current)
    const navHeight = nav.offsetHeight
    this.setState({
      height: totalHeight - navHeight
    })
  }

  // 获取案件数据
  getData () {
    const { pagination, searchText } = this.state
    const params = {
      ...pagination,
      keyword: searchText
    }
    return this.$get('/api/xapc/case/caseLikeSelect', { params })
      .then(res => {
        this.setState({
          refreshing: false,
          isLoading: false
        })
        if (res.status === 1) {
          let list = this.state.list.slice()
          list = list.concat(res.data.list)
          this.setState({
            total: res.data.total,
            list,
            dataSource: this.state.dataSource.cloneWithRows(list)
          })
        }
      }).catch(() => {
        this.setState({
          refreshing: false,
          isLoading: false
        })
      })
  }

  onRefreshHandle () {
    this.setState({
      refreshing: true,
      pagination: {
        ...this.state.pagination,
        current: 1
      },
      list: []
    }, () => {
      this.getData()
    })
  }

  changeSearch (v) {
    if (v === this.state.searchText) {
      return false
    }
    this.setState({
      searchText: v,
      pagination: {
        ...this.state.pagination,
        current: 1
      },
      isLoading: true,
      list: [],
      dataSource: this.state.dataSource.cloneWithRows([])
    }, () => {
      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.getData()
      }, 500)
    })
  }

  componentDidMount () {
    this.initListHeight()
    this.setState({
      isLoading: true
    }, () => {
      this.getData()
    })
  }

  componentDidUpdate (prev) {
    const { visible } = this.props
    if (visible && prev.visible !== visible) {
      this.initListHeight()
    }
  }

  renderRow (d, i) {
    return <Row key={i} data={d} clickHandle={() => this.props.history.push(`/admin/case-detail/${d.id}`)} />
  }

  renderSeparator (d, i) {
    if (i === String(this.state.list.length - 1)) {
      return null
    }
    return (
      <Border key={`${d}-${i}`} top={false} left={false} right={false} radius={false}>
        <div className={style.separator} />
      </Border>
    )
  }

  renderFooter () {
    const { total, list, isLoading } = this.state
    if (!isLoading) {
      if (list.length === 0) {
        return <div className={style.footer}>暂无案件</div>
      }
      if (list.length >= total) {
        return <div className={style.footer}>没有更多了</div>
      }
    } else {
      return (
        <div className={style.footer}>
          <span className={style['footer-text']}>加载中</span>
          <Icon type='loading' />
        </div>
      )
    }
  }

  render () {
    const pageSize = this.state.pagination.page_size
    return (
      <div id='CaseList' className={style.container} ref={this.containerRef}>
        <div className={style['search-box']} ref={this.navbarRef}>
          <SearchBar placeholder='请输入案号、当事人、仲裁员或秘书' onChange={v => this.changeSearch(v)} />
        </div>
        <ListView
          ref={this.listRef}
          dataSource={this.state.dataSource}
          renderRow={(d, i) => this.renderRow(d, i)}
          renderSeparator={(d, i) => this.renderSeparator(d, i)}
          style={{
            height: this.state.height,
            overflow: 'auto'
          }}
          pageSize={pageSize}
          scrollRenderAheadDistance={500}
          onEndReached={() => this.onEndReached()}
          onEndReachedThreshold={200}
          initialListSize={pageSize}
          renderFooter={() => this.renderFooter()}
          pullToRefresh={
            <PullToRefresh
              damping={60}
              indicator={this.state.down ? {} : { deactivate: '下拉刷新' }}
              refreshing={this.state.refreshing}
              onRefresh={() => this.onRefreshHandle()}
            />
          }
        />
      </div>
    )
  }
}

CaseList.propTypes = {
  // showDetail: PropTypes.func,
  visible: PropTypes.bool
}

CaseList.defaultProps = {
  showDetail: () => {},
  visible: false
}

const mapState = mapStateToProps(function (state) {
  return {
    userInfo: state.basic.userInfo
  }
})

const mapDispatch = mapDispatchToProps(function (dispatch) {
  return {

  }
})

export default connect(mapState, mapDispatch)(CaseList)
