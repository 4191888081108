import React from 'react'

import {
  Route,
  Router,
  Switch,
  Redirect
} from 'react-router-dom'
import { createBrowserHistory } from 'history'

import './Router.less'
import { RouterConfig } from './RouteConfig'
import { CSSTransition } from 'react-transition-group'

export const history = createBrowserHistory()

export default class Routers extends React.PureComponent {
  render () {
    return (
      <div>
        <Router history={history}>
          {(
            RouterConfig.map((c) => {
              return (
                <Route key={c.path} path={c.path} exact={c.exact}>
                  {({ match, history }) => {
                    return (
                      <CSSTransition
                        in={match != null}
                        timeout={500}
                        classNames={match != null ? c.push : c.pop}
                        unmountOnExit
                        key={c.path}
                      >
                        <div className='router-wrapper'>
                          <c.component history={history} />
                        </div>
                      </CSSTransition>
                    )
                  }}
                </Route>
              )
            })
          )}
          <Switch>
            <Route exact component={() => <Redirect push to='/admin' />} />
            <Route path='/login' />
            <Route path='/admin' />
            <Route path='/wework' />
          </Switch>
        </Router>
      </div>
    )
  }
}
