import React from 'react'
import PropTypes from 'prop-types'
import Component, { mapStateToProps, mapDispatchToProps } from '../Component'
import { connect } from 'react-redux'
import _ from 'loadsh'
import { Toast } from 'antd-mobile'
import Approve from '../Approve/Index'
import StepOneHistory from './FormCourt/StepOneHistory'
import StepTwoInput from './FormCourt/StepTwoInput'
import StepTwoHistory from './FormCourt/StepTwoHistory'
import StepThreeInput from './FormCourt/StepThreeInput'
import StepThreeHistory from './FormCourt/StepThreeHistory'

class FormCourt extends Component {
  // 设置组庭信息
  setFormCourtData (history, currentStep) {
    return this.$get('/api/xapc/tribunal', {
      params: {
        caseid: this.props.caseDetail.id
      }
    }).then(res => {
      if (res.status === 1) {
        const map = {}
        res.data.forEach(li => {
          map[li.id] = li
        })
        return {
          formCourt: map[this.props.flowInstanceBizid]
        }
      } else {
        Toast.fail(res.message)
      }
    })
  }

  // 更新组庭情况信息
  updateFormCourtData (id, data, oridata) {
    const d = { ...this.state.courtData, ...data }
    delete d.vars
    return this.$put(`/api/xapc/tribunal/${id}`, d)
      .then(res => {
        if (res.status !== 1) {
          throw new Error(res.message)
        }
      })
  }

  // 复制指定的字段到目标对象
  mergeData (to, from, keys) {
    keys.forEach(key => {
      to[key] = from[key]
    })
  }

  // 获取业务处主任表单初始化数据
  getBusinessLeaderFormInitialData (currentStep, otherData) {
    const court = otherData.formCourt
    const data = {}
    // 判断当事人选择情况，并将选择情况填充到领导选择框中
    if (court.arbitratorid_claimant === court.arbitratorid_respondent && court.arbitratorid_claimant) {
      data.arbitrator_recommend = court.arbitrator_claimant
      data.arbitratorid_recommend = court.arbitratorid_claimant
    }
    if (court.arbitratorid1_claimant && court.arbitratorid1_claimant !== court.arbitratorid1_respondent) {
      data.arbitrator1_recommend = court.arbitrator1_claimant
      data.arbitratorid1_recommend = court.arbitratorid1_claimant
    }
    if (court.arbitratorid1_respondent && court.arbitratorid1_respondent !== court.arbitratorid1_claimant) {
      data.arbitrator2_recommend = court.arbitrator1_respondent
      data.arbitratorid2_recommend = court.arbitratorid1_respondent
    }
    // 判断领导选择框是否为空，如果为空则将秘书推荐情况填充进来
    if (!data.arbitratorid_recommend && court.arbitratorid_recommend1) {
      data.arbitrator_recommend = court.arbitrator_recommend1
      data.arbitratorid_recommend = court.arbitratorid_recommend1
    }
    if (!data.arbitratorid1_recommend && court.arbitratorid1_recommend1) {
      data.arbitrator1_recommend = court.arbitrator1_recommend1
      data.arbitratorid1_recommend = court.arbitratorid1_recommend1
    }
    if (!data.arbitratorid2_recommend && court.arbitratorid2_recommend1) {
      data.arbitrator2_recommend = court.arbitrator2_recommend1
      data.arbitratorid2_recommend = court.arbitratorid2_recommend1
    }
    return data
  }

  // 获取秘书长表单初始化数据
  getSecretaryGeneralFormInitialData (currentStep, otherData) {
    const court = otherData.formCourt
    return {
      arbitrator: court.arbitrator_recommend,
      arbitratorid: court.arbitratorid_recommend,
      arbitrator1: court.arbitrator1_recommend,
      arbitratorid1: court.arbitratorid1_recommend,
      arbitrator2: court.arbitrator2_recommend,
      arbitratorid2: court.arbitratorid2_recommend
    }
  }

  // 获取表单初始化数据
  getFormInitialData (currentStep, otherData) {
    switch (currentStep.step_key) {
      case 'zuting02':
        return this.getBusinessLeaderFormInitialData(currentStep, otherData)
      case 'zuting04':
        return this.getSecretaryGeneralFormInitialData(currentStep, otherData)
      default:
        return {}
    }
  }

  // 业务处领导提交前数据处理
  async businessLeaderBeforeSubmit (instance, data, oridata) {
    await this.updateFormCourtData(instance.otherData.formCourt.id, data, oridata)
    const vars = {}
    const oridataKeys = ['arbitrator_recommend', 'arbitratorid_recommend', 'arbitrator1_recommend', 'arbitratorid1_recommend', 'arbitrator2_recommend', 'arbitratorid2_recommend']
    this.mergeData(vars, oridata, oridataKeys)
    vars.zuting_biz_leader_select_info = this.getLeaderSelectorDesc(instance, data, oridata)
    return {
      remark: oridata.remark,
      vars: vars
    }
  }

  // 获取领导指定仲裁员描述
  getLeaderSelectorDesc (instance, data, oridata) {
    const { formCourt } = instance.otherData
    let primarys = new Set()
    let first = new Set()
    let second = new Set()
    formCourt.tribunal_party.forEach(li => {
      if (li.party_type === '申请人') {
        primarys.add(li.arbitratorid)
        first.add(li.arbitratorid1)
      } else if (li.party_type === '被申请人') {
        primarys.add(li.arbitratorid)
        second.add(li.arbitratorid1)
      }
    })
    primarys = [...primarys]
    first = [...first]
    second = [...second]
    const pid = primarys.length === 1 && primarys[0] ? primarys[0] : false
    const fid = first.length === 1 && first[0] ? first[0] : false
    const sid = second.length === 1 && second[0] ? second[0] : false
    const str = []
    if (formCourt.type === '三人庭') {
      if (!pid) {
        str.push(`建议指定${oridata.arbitrator_recommend}为首席仲裁员。`)
      }
      if (!fid) {
        str.push(`建议指定${oridata.arbitrator1_recommend}为第一边裁。`)
      }
      if (!sid) {
        str.push(`建议指定${oridata.arbitrator2_recommend}为第二边裁。`)
      }
    } else if (!pid) {
      str.push(`建议指定${oridata.arbitrator_recommend}为独任仲裁员。`)
    }
    return str.join('')
  }

  // 格式化业务处主任数据
  formatApproveBusinessData (data) {
    data = _.cloneDeep(data)
    delete data.remark
    return data
  }

  // 秘书长提交前数据处理
  async secretaryGeneralBeforeSubmit (instance, data, oridata) {
    await this.updateFormCourtData(instance.otherData.formCourt.id, data, oridata)
    const vars = {}
    const oridataKeys = ['arbitrator', 'arbitratorid', 'arbitrator1', 'arbitratorid1', 'arbitrator2', 'arbitratorid2']
    this.mergeData(vars, oridata, oridataKeys)
    return {
      remark: oridata.remark,
      vars: vars
    }
  }

  // 在提交前处理数据
  async beforeSubmit (instance, data) {
    const { currentStep } = instance
    switch (currentStep.step_key) {
      case 'zuting02':
        return this.businessLeaderBeforeSubmit(instance, { ...instance.otherData.formCourt, ...this.formatApproveBusinessData(data) }, data)
      case 'zuting04':
        return this.secretaryGeneralBeforeSubmit(instance, { ...instance.otherData.formCourt, ...this.formatApproveBusinessData(data) }, data)
    }
    return Promise.resolve(data)
  }

  // 渲染历史
  renderHistory (action, renderFn) {
    switch (action.step_key) {
      case 'zuting01':
        return <StepOneHistory key={action.id} action={action} caseDetail={this.props.caseDetail} />
      case 'zuting02':
        return <StepTwoHistory key={action.id} action={action} caseDetail={this.props.caseDetail} />
      case 'zuting04':
        return <StepThreeHistory key={action.id} action={action} caseDetail={this.props.caseDetail} />
    }
    return renderFn(action)
  }

  // 渲染当前操作步
  renderCurrentStep (form, currentStep, renderFn, otherData) {
    switch (currentStep.step_key) {
      case 'zuting02':
        return <StepTwoInput form={form} currentStep={currentStep} caseDetail={this.props.caseDetail} otherData={otherData} />
      case 'zuting04':
        return <StepThreeInput form={form} currentStep={currentStep} caseDetail={this.props.caseDetail} otherData={otherData} />
    }
    return renderFn(currentStep)
  }

  render () {
    const { flowInstanceid, flowid, caseDetail } = this.props
    if (!caseDetail.id) {
      return null
    }
    return (
      <Approve
        flowInstanceid={flowInstanceid}
        flowid={flowid}
        renderHistory={(action, renderFn) => this.renderHistory(action, renderFn)}
        renderCurrentStep={(formRef, currentStep, renderFn, otherData) => this.renderCurrentStep(formRef, currentStep, renderFn, otherData)}
        initialCurrentStepFormData={(currentStep, otherData) => this.getFormInitialData(currentStep, otherData)}
        beforeSubmit={(instance, data) => this.beforeSubmit(instance, data)}
        afterAction={() => this.props.goBack()}
        initOtherData={(history, currentStep) => this.setFormCourtData(history, currentStep)}
      />
    )
  }
}

FormCourt.propTypes = {
  flowInstanceid: PropTypes.string,
  flowInstanceBizid: PropTypes.string,
  caseDetail: PropTypes.object,
  goBack: PropTypes.func
}

FormCourt.defaultProps = {
  flowInstanceid: '',
  flowInstanceBizid: '',
  caseDetail: {},
  goBack: () => {}
}

const mapState = mapStateToProps(function (state) {
  return {
    userInfo: state.basic.userInfo
  }
})

const mapDispatch = mapDispatchToProps(function (dispatch) {
  return {

  }
})

export default connect(mapState, mapDispatch)(FormCourt)
