// import React from 'react'
import CaseDetail from '../pages/CaseDetail'
import MailDetail from '../pages/MailDetail'
import LetterDetail from '../pages/LetterDetail'
import ApproveDetail from '../pages/ApproveDetail'
// import { Redirect } from 'react-router-dom'

export default [
  {
    path: '/admin/case-detail/:caseid',
    component: CaseDetail,
    push: 'forward',
    pop: 'back',
    exact: true
  },
  {
    path: '/admin/mail-detail/:id',
    component: MailDetail,
    push: 'forward',
    pop: 'back',
    exact: true
  },
  {
    path: '/admin/letter-detail/:id',
    component: LetterDetail,
    push: 'forward',
    pop: 'back',
    exact: true
  },
  {
    path: '/admin/approve-detail/:id',
    component: ApproveDetail,
    push: 'forward',
    pop: 'back',
    exact: false
  }
]
