import React, { useEffect, useState } from 'react'
import { InputItem, Toast, Icon } from 'antd-mobile'
import style from './Input.module.less'

function Main ({ title = '', keyName, form, extra, onClick }) {
  const { getFieldError } = form
  const [value, setValue] = useState()
  useEffect(() => {
    setValue(extra === '请选择' ? undefined : extra)
  }, [extra])
  return (
    <div>
      <InputItem
        labelNumber={title.length + 1}
        error={getFieldError(keyName)}
        onErrorClick={() => Toast.info(`${title}不能为空`, 2)}
        placeholder='请选择'
        editable={false}
        clear
        value={value}
        onClick={onClick}
        extra={<Icon type='right' className={style['right-arrow']} />}
      >
        {title}
      </InputItem>
    </div>
  )
}

export default Main
