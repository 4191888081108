import React from 'react'
import ReactDOM from 'react-dom'
import Component, { mapStateToProps, mapDispatchToProps } from '../../components/Component'
import { connect } from 'react-redux'
import { Toast, PullToRefresh } from 'antd-mobile'
import style from './BusinessDesktop.module.less'
import Icon from '../../components/Icon'

class BusinessDesktop extends Component {
  state = {
    refreshing: false,
    height: document.documentElement.clientHeight * 3 / 4,
    dealingCaseNumber: 0,
    toCourtNumber: 0,
    willTimeoutNumber: 0,
    timeoutNumber: 0
  }

  containerRef = React.createRef()

  initHeight () {
    this.setState({
      height: ReactDOM.findDOMNode(this.containerRef.current).offsetHeight
    })
  }

  // 获取在办案件数
  getDealingCaseNumber () {
    const { id } = this.props.userInfo
    return this.$get('/api/xapc/case', {
      params: {
        condition: JSON.stringify([{ k: 'status', o: 'in', v: '移交办案,已组庭,已结案' }, { k: 'operatorid', o: '=', v: id }]
        ),
        conly: true
      }
    }).then(res => {
      if (res.status === 1) {
        return res.data
      } else {
        Toast.fail(res.message)
        return 0
      }
    })
  }

  // 获取待组庭案件数
  getToCourtNumber () {
    const { id } = this.props.userInfo
    return this.$get('/api/xapc/case', {
      params: {
        condition: JSON.stringify([{ k: 'status', o: 'in', v: '移交办案' }, { k: 'operatorid', o: '=', v: id }]
        ),
        conly: true
      }
    }).then(res => {
      if (res.status === 1) {
        return res.data
      } else {
        Toast.fail(res.message)
        return 0
      }
    })
  }

  // 获取即将延期案件数
  getWillTimeoutNumber () {
    return Promise.resolve(0)
  }

  // 获取延期案件数
  getTimeoutNumber () {
    return Promise.resolve(0)
  }

  // 获取统计数据
  getStatistics () {
    this.setState({ refreshing: true })
    Promise.all([this.getDealingCaseNumber(), this.getToCourtNumber(), this.getWillTimeoutNumber(), this.getTimeoutNumber()])
      .then(res => {
        this.setState({ refreshing: false })
        this.setState({
          dealingCaseNumber: res[0],
          toCourtNumber: res[1],
          willTimeoutNumber: res[2],
          timeoutNumber: res[3]
        })
      }).catch(() => {
        this.setState({ refreshing: false })
      })
  }

  componentDidMount () {
    this.initHeight()
    this.getStatistics()
  }

  renderInfo () {
    return (
      <div className={style['container-inner']}>
        <div className={style.header}>
          芜湖仲裁委员会
        </div>
        <div className={style.block}>
          <div className={style['icon-container']}>
            <Icon name='edit-filling' size='40px' color='#fff' />
          </div>
          <div className={style['message-container']}>
            <div className={style['message-title']}>在办案件</div>
            <div className={style['message-text']}>
              <span className={style.num}>{this.state.dealingCaseNumber}</span>
              <span className={style.unit}>件</span>
            </div>
          </div>
        </div>
        <div className={style.block}>
          <div className={style['icon-container']}>
            <Icon name='user-filling' size='40px' color='#fff' />
          </div>
          <div className={style['message-container']}>
            <div className={style['message-title']}>待组庭</div>
            <div className={style['message-text']}>
              <span className={style.num}>{this.state.toCourtNumber}</span>
              <span className={style.unit}>件</span>
            </div>
          </div>
        </div>
        <div className={style.block}>
          <div className={style['icon-container']}>
            <Icon name='history-filling' size='40px' color='#fff' />
          </div>
          <div className={style['message-container']}>
            <div className={style['message-title']}>即将延期案件</div>
            <div className={style['message-text']}>
              <span className={style.num}>{this.state.willTimeoutNumber}</span>
              <span className={style.unit}>件</span>
            </div>
          </div>
        </div>
        <div className={style.block}>
          <div className={style['icon-container']}>
            <Icon name='notification-filling' size='40px' color='#fff' />
          </div>
          <div className={style['message-container']}>
            <div className={style['message-title']}>延期案件</div>
            <div className={style['message-text']}>
              <span className={style.num}>{this.state.timeoutNumber}</span>
              <span className={style.unit}>件</span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render () {
    return (
      <div className={style.container} ref={this.containerRef}>
        <PullToRefresh
          refreshing={this.state.refreshing}
          damping={60}
          style={{
            height: this.state.height + 'px',
            overflow: 'auto'
          }}
          onRefresh={() => this.getStatistics()}
        >
          {this.renderInfo()}
        </PullToRefresh>
      </div>
    )
  }
}

const mapState = mapStateToProps(function (state) {
  return {
    userInfo: state.basic.userInfo
  }
})

const mapDispatch = mapDispatchToProps(function (dispatch) {
  return {

  }
})

export default connect(mapState, mapDispatch)(BusinessDesktop)
