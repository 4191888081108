import React, { Component } from 'react'
import style from './DefaultDesktop.module.less'

export default class DefaultDesktop extends Component {
  render () {
    return (
      <div className={style.container}>
        欢迎使用芜湖仲裁委信息化管理平台
      </div>
    )
  }
}
