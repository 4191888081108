import React, { useState, useEffect } from 'react'
import { Button, Toast } from 'antd-mobile'
import PropTypes from 'prop-types'
import style from './sidebar.module.less'
import Input from '../Input'
import SelectorTags from '../SelectorTags'
import { $get } from '../../assets/js/authRequest'

// const appointDateList = [
//   { label: '全部', value: '全部' },
//   { label: '2021年3月1日', value: '2021年3月1日' },
//   { label: '2021年6月1日', value: '2021年6月1日' },
//   { label: '21年3月1日之前', value: '21年3月1日之前' }
// ]

function ArbitratorSidebar ({ onChange, hide }) {
  const [causeAction, setCauseAction] = useState('')
  const [professional, setProfessional] = useState('')
  const [area, setArea] = useState('')
  const [occupation, setOccupation] = useState([])
  const [occupationOptions, setOccupationOptions] = useState([])
  const [causeActionClass, setCauseActionClass] = useState('')
  const [appointDate, setAppointDate] = useState(['全部'])
  // 重置
  function reset () {
    setCauseAction('')
    setProfessional('')
    setOccupation(occupationOptions.map(li => li.value))
    setArea('')
    setCauseActionClass('')
    setAppointDate(['全部'])
  }
  useEffect(() => {
    getOccupationOptions(v => {
      const values = v.map(li => li.value)
      setOccupationOptions(v)
      setOccupation(values)
    })
  }, [])
  useEffect(() => {
    if (occupationOptions.length === 0) {
      return
    }
    onChange({ occupation: occupationOptions.map(li => li.value) })
  }, [occupationOptions, onChange])
  return (
    <div className={style.sidebar}>
      <div className={style.title}>擅长</div>
      <div className={style.line}>
        <Input value={causeAction} onChange={v => setCauseAction(v)} placeholder='请输入擅长' />
      </div>
      <div className={style.title}>专业</div>
      <div className={style.line}>
        <Input value={professional} onChange={v => setProfessional(v)} placeholder='请输入专业' />
      </div>
      <div className={style.title}>所在区域</div>
      <div className={style.line}>
        <Input value={area} onChange={v => setArea(v)} placeholder='请输入所在区域' />
      </div>
      {/* <div className={style.title}>金融专业</div>
      <div className={style.line}>
        <Input value={causeActionClass} onChange={v => setCauseActionClass(v)} placeholder='请输入金融专业' />
      </div> */}
      <div className={style.title}>职业分类</div>
      <div className={style.line}>
        <SelectorTags options={occupationOptions} value={occupation} onChange={v => setOccupation(v)} />
      </div>
      {/* <div className={style.title}>聘用时间</div>
      <div className={style.line}>
        <SelectorTags options={appointDateList} value={appointDate} onChange={v => setAppointDate(v)} single />
      </div> */}
      <div className={style['operate-btns']}>
        <div className={style.btn}>
          <Button onClick={() => reset()}>重置</Button>
        </div>
        <div className={style.btn}>
          <Button type='primary' onClick={() => { hide(); onChange({ causeAction, professional, area, occupation, causeActionClass, appointDate }) }}>确定</Button>
        </div>
      </div>
    </div>
  )
}

// 获取职业可选项
function getOccupationOptions (useOptions) {
  return $get('/api/xapc/dictionary', {
    params: {
      types: '仲裁员职业分类'
    }
  }).then(res => {
    if (res.status !== 1) {
      return Toast.fail(res.message)
    }
    // 设置枚举字典
    const dictionaries = formatDictionaries(res.data)
    useOptions((dictionaries['仲裁员职业分类'] || []).map(li => ({ label: li.title, value: li.value })))
  })
}

// 格式化枚举字典
function formatDictionaries (dictionaries = []) {
  const result = {}
  dictionaries.forEach(d => {
    result[d.type] = d.list
  })
  return result
}

ArbitratorSidebar.propTypes = {
  onChange: PropTypes.func,
  hide: PropTypes.func
}

ArbitratorSidebar.defaultProps = {
  onChange: () => {},
  hide: () => {}
}

export default ArbitratorSidebar
