import React from 'react'
import PropTypes from 'prop-types'
import { NavBar, Icon, Result, Toast } from 'antd-mobile'
import Component, { mapStateToProps, mapDispatchToProps } from '../components/Component'
import { connect } from 'react-redux'
import style from './ApproveDetail.module.less'
import RegisterCase from '../components/ApproveDetail/RegisterCase'
import Reduction from '../components/ApproveDetail/Reduction'
import Postpone from '../components/ApproveDetail/Postpone'
import Preservation from '../components/ApproveDetail/Preservation'
import FormCourt from '../components/ApproveDetail/FormCourt'
import CloseCase from '../components/ApproveDetail/CloseCase'
import TransferTheFiles from '../components/ApproveDetail/TransferTheFiles'
import UnSupport from '../components/ApproveDetail/UnSupport'
import OtherRouters from '../router/OtherRouters'
import ApproveDetailRouteConfig from '../router/ApproveDetailRouteConfig'

class ApproveDetail extends Component {
  state = {
    animating: false,
    route: {},
    title: '呈批详情',
    flow_instanceid: '',
    flow_instance_bizid: '',
    flowid: '',
    caseid: '',
    loading: false,
    isError: false,
    caseDetail: {}
  }

  // 获取呈批消息详情
  getApproveDetail () {
    Toast.loading('加载中', 120)
    this.$get(`/api/xapc/message/${this.props.match.params.id}`)
      .then(res => {
        if (res.status !== 1) {
          Toast.hide()
          return Toast.fail(res.message)
        }
        this.getApproveRoute(res.data)
      })
      .catch(e => {
        Toast.hide()
      })
  }

  // 获取呈批路由
  getApproveRoute (d) {
    let route = {}
    try {
      route = JSON.parse(d.route)
    } catch (e) {
      return Toast.fail('获取路由信息失败')
    }
    const data = {
      route,
      flow_instanceid: d.flow_instanceid,
      flow_instance_bizid: d.flow_instance_bizid,
      flowid: d.flowid,
      caseid: d.caseid,
      title: d.title
    }
    this.setState(data)
    this.getCaseDetail(data)
  }

  // 获取数据
  getCaseDetail (approveData) {
    this.$get(`/api/xapc/case/${approveData.caseid}`)
      .then(res => {
        Toast.hide()
        this.setState({ ready: true })
        if (this.status === false) {
          return false
        }
        if (res.status === 1) {
          this.setState({
            caseDetail: res.data,
            ...approveData
          })
        } else {
          this.setState({ isError: true })
          Toast.fail(res.message, 2)
        }
      }).catch(() => {
        this.setState({ isError: true })
      })
  }

  // 前往案件详情
  toCaseDetail () {
    const id = this.props.match.params.id
    this.props.history.push(`/admin/approve-detail/${id}/case-detail/${this.state.caseid}`)
  }

  componentDidMount () {
    this.getApproveDetail()
  }

  // 渲染呈批详情
  renderDetail () {
    const { route: r, flow_instanceid: flowInstanceid, flow_instance_bizid: flowInstanceBizid, flowid, caseid, caseDetail } = this.state
    if (!r || !r.meta) {
      return (
        <div className={style.result}>
          <Result
            img={<Icon type='cross-circle-o' className={style['result-icon']} />}
            title='呈批类型不存在'
            message='请联系管理员'
          />
        </div>
      )
    }
    const d = { flowInstanceid, flowid, caseid, caseDetail, flowInstanceBizid }
    switch (r.meta.operate) {
      case 'lian': // 指定办案秘书呈批
        return <RegisterCase {...d} goBack={() => this.props.history.goBack()} />
      case 'jianmian': // 减免呈批
        return <Reduction {...d} goBack={() => this.props.history.goBack()} />
      case 'zanhuan': // 暂缓呈批
        return <Postpone {...d} goBack={() => this.props.history.goBack()} />
      case 'baoquan': // 保全呈批
        return <Preservation {...d} goBack={() => this.props.history.goBack()} />
      case 'jiean': // 结案呈批
        return <CloseCase {...d} goBack={() => this.props.history.goBack()} />
      case 'dangandiaoqu': // 调档呈批
        return <TransferTheFiles {...d} goBack={() => this.props.history.goBack()} />
      case 'zuting': // 组庭呈批
        return <FormCourt {...d} goBack={() => this.props.history.goBack()} />
      default:
        return <UnSupport />
    }
  }

  render () {
    return (
      <div className={style.container} id='approveDetail'>
        <div className={style.header}>
          <NavBar
            ref={this.navbarRef}
            mode='light'
            icon={<Icon type='left' size='lg' />}
            onLeftClick={() => this.props.history.goBack()}
            rightContent={<span className={style['nav-bar-right']} onClick={() => this.toCaseDetail()}>案件详情</span>}
          >{this.state.title}
          </NavBar>
        </div>
        <div className={style['detail-container']}>
          {this.state.flow_instanceid && this.renderDetail()}
        </div>
        <OtherRouters routes={ApproveDetailRouteConfig} zIndex={2} />
      </div>
    )
  }
}

ApproveDetail.propTypes = {
  // id: PropTypes.string,
  data: PropTypes.object
}

ApproveDetail.defaultProps = {
  id: '',
  data: {}
}

const mapState = mapStateToProps(function (state) {
  return {
    userInfo: state.basic.userInfo
  }
})

const mapDispatch = mapDispatchToProps(function (dispatch) {
  return {

  }
})

export default connect(mapState, mapDispatch)(ApproveDetail)
