import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'loadsh'
import { TextTitle, TextList, TextItem } from '../../TextList'

export default class SecretaryHistory extends Component {
  state = {
    keyTitleMap: {},
    keyValueMap: {}
  }

  // 格式化时间
  formatTime (time) {
    if (time) {
      return moment(time * 1000).format('YYYY-MM-DD HH:mm')
    }
  }

  initKeyTitleMap () {
    const map = {}
    const vMap = {}
    const list = _.get(this.props.action || {}, 'vars', [])
    list.forEach(li => {
      map[li.key] = li.title
      vMap[li.key] = li.value
    })
    this.setState({
      keyTitleMap: map,
      keyValueMap: vMap
    })
  }

  getFeeAfterDerate () {
    const varsMap = this.state.keyValueMap
    const claim = this.props.caseDetail.claim || {}
    const acceptance = (varsMap.fee_acceptance_derate || {}).value || 0
    const processing = (varsMap.fee_processing_derate || {}).value || 0
    const fee = (claim.fee_acceptance_derate !== null || claim.fee_processing_derate !== null) ? claim.fee : claim.fee - acceptance - processing
    return `${this.formatMoney(fee)}元`
  }

  getAcceptanceAfterDerate () {
    const varsMap = this.state.keyValueMap
    const claim = this.props.caseDetail.claim || {}
    const acceptance = (varsMap.fee_acceptance_derate || {}).value || 0
    const fee = claim.fee_acceptance_derate !== null ? claim.fee_acceptance : claim.fee_acceptance - acceptance
    return `${this.formatMoney(fee)}元`
  }

  getProcessingAgterDerate () {
    const varsMap = this.state.keyValueMap
    const claim = this.props.caseDetail.claim || {}
    const processing = (varsMap.fee_processing_derate || {}).value || 0
    const fee = claim.fee_processing_derate ? claim.fee_processing : claim.fee_processing - processing
    return `${this.formatMoney(fee)}元`
  }

  // 格式化金钱
  formatMoney (money) {
    const m = String(money)
    if (!/^\d*$/.test(m)) {
      return m
    }
    return m.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  componentDidUpdate (prev) {
    if (this.props.action !== prev.action) {
      this.initKeyTitleMap()
    }
  }

  componentDidMount () {
    this.initKeyTitleMap()
  }

  render () {
    const { keyTitleMap, keyValueMap } = this.state
    const d = this.props.action
    return (
      <div>
        <TextTitle rightContent={this.formatTime(d.complete_datetime)}>{d.step_name}</TextTitle>
        <TextList>
          <TextItem title='操作者' value={d.operator} />
          <TextItem title='操作' value={d.action_name} />
          <TextItem title={keyTitleMap.fee_acceptance_derate} value={this.formatMoney(keyValueMap.fee_acceptance_derate) + '元'} />
          <TextItem title={keyTitleMap.fee_processing_derate} value={this.formatMoney(keyValueMap.fee_processing_derate) + '元'} />
          <TextItem title='减免后受理费' value={this.getAcceptanceAfterDerate()} />
          <TextItem title='减免后处理费' value={this.getProcessingAgterDerate()} />
          <TextItem title='减免后仲裁费' value={this.getFeeAfterDerate()} />
          <TextItem title={keyTitleMap.jianmian_remark} value={keyTitleMap.jianmian_remark || '无'} />
          <TextItem title={d.remark_alias || '备注'} value={d.remark || '无'} />
        </TextList>
      </div>
    )
  }
}

SecretaryHistory.propTypes = {
  action: PropTypes.object,
  caseDetail: PropTypes.object
}

SecretaryHistory.defaultProps = {
  action: {},
  caseDetail: {}
}
