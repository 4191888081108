import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { ListView, PullToRefresh, Icon, SearchBar, NavBar } from 'antd-mobile'
import Component, { mapStateToProps, mapDispatchToProps } from '../components/Component'
import { connect } from 'react-redux'
import Border from '../components/Border'
import style from './MailList.module.less'
import MailRow from '../components/MailList/MailRow'
import AdminDrawerContext from '../components/Drawer/AdminDrawerContext'

class MailList extends Component {
  constructor (props) {
    super(props)

    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1 !== row2
    })

    this.state = {
      dataSource,
      list: [],
      isLoading: false,
      refreshing: false,
      height: document.documentElement.clientHeight * 3 / 4,
      searchText: '',
      total: 0,
      pagination: {
        current: 1,
        page_size: 20
      }
    }
  }

  listRef = React.createRef()
  containerRef = React.createRef()
  navbarRef = React.createRef()

  // 到达底部加载更多
  onEndReached () {
    const { list, total, pagination } = this.state
    const { current } = pagination
    if (list.length >= total) {
      return false
    }
    this.setState({
      isLoading: true,
      pagination: {
        ...this.state.pagination,
        current: current + 1
      }
    }, () => {
      this.getData()
    })
  }

  // 初始化列表容器高度
  initListHeight () {
    const totalHeight = this.containerRef.current.offsetHeight
    const nav = ReactDOM.findDOMNode(this.navbarRef.current)
    const navHeight = nav.offsetHeight
    this.setState({
      height: totalHeight - navHeight
    })
  }

  // 获取查询条件
  getCondition () {
    const { causeAction, professional, area, occupation, causeActionClass, appointDate = [] } = this.props.formData
    const condition = []
    if (causeAction) {
      condition.push({ k: 'special', o: 'like', v: `%${causeAction}%` })
    }
    if (professional) {
      condition.push({ k: 'professional', o: 'like', v: `%${professional}%` })
    }
    if (area) {
      condition.push({ k: 'area', o: 'like', v: `%${area}%` })
    }
    if (occupation && occupation.length > 0) {
      condition.push({ k: 'occupation', o: 'in', v: occupation.join() })
    }
    if (causeActionClass) {
      condition.push({ k: 'causeaction_class', o: 'like', v: `%${causeActionClass}%` })
    }
    const date = appointDate[0]
    if (date === '2021年3月1日') {
      condition.push({ k: 'appoint_datetime', o: '=', v: '1614528000' })
    } else if (date === '2021年6月1日') {
      condition.push({ k: 'appoint_datetime', o: '=', v: '1622476800' })
    } else if (date === '21年3月1日之前') {
      condition.push({ k: 'appoint_datetime', o: 'null' })
    }
    return condition
  }

  // 获取仲裁员数据
  getData () {
    const { pagination, searchText } = this.state
    const params = {
      ...pagination
    }
    const condition = this.getCondition()
    if (searchText) {
      condition.push({ k: 'name', o: 'like', v: `%${searchText}%` })
    }
    params.condition = JSON.stringify(condition)
    return this.$get('/api/xapc/arbitrator', { params })
      .then(res => {
        this.setState({
          refreshing: false,
          isLoading: false
        })
        if (res.status === 1) {
          let list = this.state.list.slice()
          list = list.concat(res.data.list)
          this.setState({
            total: res.data.total,
            list,
            dataSource: this.state.dataSource.cloneWithRows(list)
          })
        }
      }).catch(() => {
        this.setState({
          refreshing: false,
          isLoading: false
        })
      })
  }

  onRefreshHandle () {
    this.setState({
      refreshing: true,
      pagination: {
        ...this.state.pagination,
        current: 1
      },
      list: []
    }, () => {
      this.getData()
    })
  }

  changeSearch (v) {
    this.setState({
      searchText: v,
      pagination: {
        ...this.state.pagination,
        current: 1
      },
      isLoading: true,
      list: [],
      dataSource: this.state.dataSource.cloneWithRows([])
    }, () => {
      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.getData()
      }, 500)
    })
  }

  componentDidMount () {
    this.initListHeight()
    this.setState({
      isLoading: true
    }, () => {
      this.getData()
    })
  }

  componentDidUpdate (prev) {
    const { visible, formData } = this.props
    if (visible && prev.visible !== visible) {
      this.initListHeight()
    }
    if (prev.formData !== formData) {
      this.onRefreshHandle()
    }
  }

  renderRow (d, i) {
    return <MailRow key={i} data={d} clickHandle={() => this.props.history.push(`/admin/mail-detail/${d.id}`)} />
  }

  renderSeparator (d, i) {
    if (i === String(this.state.list.length - 1)) {
      return null
    }
    return (
      <Border key={`${d}-${i}`} top={false} left={false} right={false} radius={false}>
        <div className={style.separator} />
      </Border>
    )
  }

  renderFooter () {
    const { total, list, isLoading } = this.state
    if (list.length >= total && !isLoading) {
      return <div className={style.footer}>没有更多了</div>
    } else {
      return (
        <div className={style.footer}>
          <span className={style['footer-text']}>加载中</span>
          <Icon type='loading' />
        </div>
      )
    }
  }

  renderNav () {
    return (
      <AdminDrawerContext.Consumer>
        {({ setDrawerOpening, setCurrentDrawer }) => (
          <NavBar
            mode='light'
            rightContent={<span className={style['nav-btn']} onClick={() => { setDrawerOpening(true); setCurrentDrawer('arbitrator') }}>高级搜索</span>}
          />
        )}
      </AdminDrawerContext.Consumer>
    )
  }

  render () {
    const pageSize = this.state.pagination.page_size
    return (
      <div id='MailList' className={style.container} ref={this.containerRef}>
        <div className={style['search-box']} ref={this.navbarRef}>
          {this.renderNav()}
          <SearchBar placeholder='搜索仲裁员名字' onChange={v => this.changeSearch(v)} />
        </div>
        <ListView
          ref={this.listRef}
          dataSource={this.state.dataSource}
          renderRow={(d, i) => this.renderRow(d, i)}
          renderSeparator={(d, i) => this.renderSeparator(d, i)}
          style={{
            height: this.state.height,
            overflow: 'auto'
          }}
          pageSize={pageSize}
          initialListSize={pageSize}
          scrollRenderAheadDistance={500}
          onEndReached={() => this.onEndReached()}
          onEndReachedThreshold={200}
          renderFooter={() => this.renderFooter()}
          pullToRefresh={
            <PullToRefresh
              damping={60}
              indicator={this.state.down ? {} : { deactivate: '下拉刷新' }}
              refreshing={this.state.refreshing}
              onRefresh={() => this.onRefreshHandle()}
            />
          }
        />
      </div>
    )
  }
}

MailList.propTypes = {
  // showDetail: PropTypes.func,
  visible: PropTypes.bool,
  formData: PropTypes.object
}

MailList.defaultProps = {
  showDetail: () => {},
  visible: false,
  formData: {}
}

const mapState = mapStateToProps(function (state) {
  return {
    userInfo: state.basic.userInfo
  }
})

const mapDispatch = mapDispatchToProps(function (dispatch) {
  return {

  }
})

export default connect(mapState, mapDispatch)(MailList)
