import * as T from './actionTypes'

// 设置基本信息
export const setUserInfo = (data) => ({ type: T.SET_USERINFO, data }) // 设置用户信息
export const setToken = (data) => ({ type: T.SET_TOKEN, data }) // 设置token
export const setSendTime = (data) => ({ type: T.SET_SEND_TIME, data }) // 设置发送时间
export const setLoginMobile = (data) => ({ type: T.SET_LOGIN_MOBILE, data }) // 设置发送短信的手机号

// 全局事件相关
export const addScrollEvent = (data) => ({ type: T.ADD_SCROLL_EVENT, data }) // 添加滚动事件处理函数
export const delScrollEvent = (data) => ({ type: T.DEL_SCROLL_EVENT, data }) // 移除滚动事件处理函数
export const clearScrollEvent = (data) => ({ type: T.CLEAR_SCROLL_EVENT, data }) // 清空滚动事件处理函数

export const addResizeEvent = (data) => ({ type: T.ADD_RESIZE_EVENT, data }) // 添加窗口大小改变事件处理函数
export const delResizeEvent = (data) => ({ type: T.DEL_RESIZE_EVENT, data }) // 移除窗口大小改变事件处理函数
export const clearResizeEvent = (data) => ({ type: T.CLEAR_RESIZE_EVENT, data }) // 清空窗口大小改变事件处理函数
